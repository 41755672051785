import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { components } from 'react-select'

import { Select } from '@components/Form'

import { LinkGroups, LinkGroupStylesConfig, LinkGroupTitles } from '@msteams/components/Navigation/utils'

import { LinkGroupData } from '@msteams/components/Navigation/types'
import { useIsAdmin } from '@src/helpers'

interface FormProps {
  receivable: string | null
  payable: string | null
  activeLinkGroup: LinkGroupData | null
}

const SelectNavigationList = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isAdmin = useIsAdmin()

  const formik = useFormik<FormProps>({
    initialValues: {
      receivable: null,
      payable: null,
      activeLinkGroup: null,
    },
    onSubmit: () => {
      return undefined
    },
  })

  const handlePathSelect = (e: { target: { value: string } }, linkGroup: LinkGroupData) => {
    const path = e.target.value
    const activeLinkGroup = formik.values.activeLinkGroup
    navigate(path)
    formik.setFieldValue(linkGroup.type, path)
    if (activeLinkGroup && activeLinkGroup?.type !== linkGroup.type) {
      formik.setFieldValue(activeLinkGroup.type, null)
    }
    formik.setFieldValue('activeLinkGroup', linkGroup)
  }

  useEffect(() => {
    const { activeLinkGroup } = formik.values
    if (activeLinkGroup && !activeLinkGroup.isActive(pathname)) {
      formik.setFieldValue(activeLinkGroup.type, null)
    }
  }, [pathname])

  return (
    <>
      {LinkGroups.map((linkGroup, i) => (
        <Select
          className="!mb-0"
          options={linkGroup.getOptions(isAdmin)}
          key={i}
          onChange={(option) => handlePathSelect(option, linkGroup)}
          placeholder={LinkGroupTitles[linkGroup.type]}
          value={formik.values[linkGroup.type]}
          isSearchable={false}
          components={{
            IndicatorSeparator: null,
            SingleValue: (props) => {
              return (
                <components.SingleValue {...props}>
                  {LinkGroupTitles[linkGroup.type]}: {props.children}
                </components.SingleValue>
              )
            },
          }}
          styles={LinkGroupStylesConfig}
        />
      ))}
    </>
  )
}

export default SelectNavigationList
