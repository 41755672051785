import React from 'react'

import OrganizationMenu from '@components/Navigation/OrganizationMenu'
import LinkList from '@msteams/components/Navigation/components/LinksList'
import SelectNavigationList from '@msteams/components/Navigation/components/SelectNavigationList'

const Navigation = () => {
  return (
    <div className="flex gap-2 mb-3 flex-col md:flex-row  md:items-center overflow-scroll pb-2 pt-1 ">
      <OrganizationMenu
        align="start"
        direction="bottom"
        classes={{ menu: ' !ml-0 !mt-1 w-[224px]', button: 'mx-1' }}
        canAddOrganization={false}
        hideFinancialsList
        canEditOrganization={false}
      />
      <LinkList />
      <SelectNavigationList />
    </div>
  )
}

export default Navigation
