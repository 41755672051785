import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import cx from 'clsx'

import { SingleLinks } from '@msteams/components/Navigation/utils'

const LinkList = () => {
  const { pathname } = useLocation()

  return (
    <div className="flex gap-1.5">
      {SingleLinks.map((link, i) => (
        <NavLink
          key={i}
          to={link.to}
          className={() => {
            return cx(
              '!text-gray-700 border-2 border-gray-200 rounded-lg flex gap-1 px-3 py-1 font-medium h-9 items-center',
              link.isActive(pathname) && '!bg-purple-50 !text-violet-700 !font-medium border-transparent',
              !link.isActive(pathname) && 'hover:border-purple-300',
            )
          }}
        >
          {link.label}
        </NavLink>
      ))}
    </div>
  )
}
export default LinkList
