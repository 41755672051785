import i18n from 'i18next'
import routes from '@msteams/Routes/routes'
import { generatePath, matchPath } from 'react-router-dom'
import { ContactParty } from '@models/ApproveitAccounting'
import { LinkGroupData, LinkGroupType } from '@msteams/components/Navigation/types'
import { ControlProps, CSSObjectWithLabel, DropdownIndicatorProps, SingleValueProps } from 'react-select'

export const LinkGroupTitles = {
  [LinkGroupType.payable]: i18n.t('common:navigation.accountsPayable'),
  [LinkGroupType.receivable]: i18n.t('common:navigation.accountsReceivable'),
}

export const LinkGroups: LinkGroupData[] = [
  {
    getOptions: (isAdmin: boolean) => {
      const options = [
        { label: i18n.t('common:navigation.bills'), value: routes.private.bills },
        {
          label: i18n.t('common:navigation.purchaseOrders'),
          value: routes.private.purchaseOrders,
        },
        {
          label: i18n.t('common:navigation.rfq'),
          value: routes.private.rfqs,
        },
      ]
      if (isAdmin) {
        options.push({
          label: i18n.t('common:navigation.vendors'),
          value: generatePath(routes.private.contacts, { contactParty: ContactParty.vendor }),
        })
      }
      return options
    },
    isActive: (path: string) => {
      return !![
        routes.private.purchaseOrders,
        routes.private.purchaseOrdersById,
        routes.private.bills,
        routes.private.billById,
        routes.private.contactsList,
        routes.private.contact,
        routes.private.contactContract,
        routes.private.contactContracts,
        routes.private.rfqs,
        routes.private.rfqProposalsById,
        routes.private.rfqById,
      ].find((route) => matchPath(route, path))
    },
    type: LinkGroupType.payable,
  },
  {
    getOptions: (isAdmin: boolean) => {
      const options = [
        { label: i18n.t('common:navigation.invoices'), value: routes.private.invoices },
        {
          label: i18n.t('common:navigation.quotes'),
          value: routes.private.quotes,
        },
      ]
      if (isAdmin) {
        options.push({
          label: i18n.t('common:navigation.customers'),
          value: generatePath(routes.private.contacts, { contactParty: ContactParty.customer }),
        })
      }
      return options
    },
    isActive: (path: string) => {
      return !![
        routes.private.invoices,
        routes.private.invoiceById,
        routes.private.quotes,
        routes.private.quoteById,
        routes.private.contactsList,
        routes.private.contact,
        routes.private.contactContract,
        routes.private.contactContracts,
      ].find((route) => matchPath(route, path))
    },
    type: LinkGroupType.receivable,
  },
]

export const SingleLinks = [
  {
    to: routes.private.approvalRequests,
    label: i18n.t('common:navigation.approvalRequests'),
    isActive: (path: string) =>
      [
        routes.private.approvalRequests,
        routes.private.approvalRequests,
        routes.private.approvalRequestsById,
        routes.private.approvalRequestsByIdAction,
        routes.private.approvalRequest,
        routes.private.approvalRequestEditById,
      ].find((match) => matchPath(match, path)),
  },
  {
    to: routes.private.pipelines,
    label: i18n.t('common:navigation.workflows'),
    isActive: (path: string) =>
      [
        routes.private.processes,
        routes.private.pipelineProcesses,
        routes.private.pipelines,
        routes.private.pipelineProcessesById,
        routes.private.pipelineProcesses,
        routes.private.pipelineApprovalRequest,
        routes.private.pipelineById,
      ].find((match) => matchPath(match, path)),
  },
  {
    to: routes.private.tasks,
    label: i18n.t('common:navigation.tasks'),
    isActive: (path: string) => matchPath(routes.private.tasks, path) || matchPath(routes.private.taskBoards, path),
  },
]

export const LinkGroupStylesConfig = {
  control: (base: CSSObjectWithLabel, props: ControlProps) => {
    return {
      ...base,
      borderRadius: 8,
      backgroundColor: props.selectProps.value ? ' rgb(250, 245, 255,1)' : 'white',
    }
  },
  dropdownIndicator: (base: CSSObjectWithLabel, props: DropdownIndicatorProps) => ({
    ...base,
    transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'rgba(55, 65, 81, 1)',
    fontWeight: '500',
  }),
  singleValue: (base: CSSObjectWithLabel, props: SingleValueProps) => ({
    ...base,
    color: props.selectProps.value ? ' rgba(67, 56, 202, 1)' : 'black',
    fontWeight: '500',
  }),
}
