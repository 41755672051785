import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import './i18n'

import MSTeamsApp from '@msteams/App'

import 'react-datepicker/dist/react-datepicker.css'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import './index.css'

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
})

const MSTeamsAppCmp = (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MSTeamsApp />
    </QueryClientProvider>
  </React.StrictMode>
)

const root = createRoot(document.getElementById('root')!)

root.render(MSTeamsAppCmp)
