import React from 'react'
import { createHashRouter, Navigate } from 'react-router-dom'

import lazy from '@helpers/lazy'
import routes from '@msteams/Routes/routes'
import ContactListLayout from '@pages/Accounting/Contacts/components/ContactListLayout'
import { ProtectedRoute } from '@src/Routes'
import ContactLayout from '@pages/Accounting/Contacts/components/ContactLayout'
import AppLayout from '@msteams/components/AppLayout'
import OnboardingLayout from '@msteams/components/OnboardingLayout'

const ContractsList = lazy(() => import('@src/pages/Accounting/Contracts/List'), 'ContractsList')
const ContractForm = lazy(() => import('@src/pages/Accounting/Contacts/Contracts/ContractForm'), 'ContractForm')
const QuotesList = lazy(() => import('@src/pages/Accounting/Quotes/List'), 'QuotesList')
const QuoteDetails = lazy(() => import('@src/pages/Accounting/Quotes/Details'), 'QuoteDetails')
const VendorsList = lazy(() => import('@src/pages/Accounting/Contacts/ContactsList'), 'VendorsList')
const VendorForm = lazy(() => import('@src/pages/Accounting/Contacts/ContactForm'), 'VendorForm')
const InvoicesList = lazy(() => import('@src/pages/Accounting/Invoices/List'), 'InvoicesList')
const InvoiceDetails = lazy(() => import('@src/pages/Accounting/Invoices/Details'), 'InvoiceDetails')
const PurchaseOrdersList = lazy(() => import('@src/pages/Accounting/PurchaseOrders/List'), 'PurchaseOrdersList')
const PurchaseOrderDetails = lazy(() => import('@src/pages/Accounting/PurchaseOrders/Details'), 'PurchaseOrderDetails')
const VendorContractsList = lazy(
  () => import('@src/pages/Accounting/Contacts/Contracts/ContractsList'),
  'VendorContractsList',
)

const ApprovalRequests = lazy(() => import('@msteams/pages/ApprovalRequests'), 'ApprovalRequests')
const ApprovalRequestDetails = lazy(() => import('@msteams/pages/ApprovalRequestDetails'), 'ApprovalRequestDetails')

const ApprovalRequestsForm = lazy(
  () => import('@src/pages/ApprovalRequests/RequestForm/CustomForm'),
  'ApprovalRequestsForm',
)

const PipelineBuilder = lazy(() => import('@pages/Pipelines/Builder'), 'PipelineBuilder')
const PipelinesList = lazy(() => import('@pages/Pipelines/PipelinesList'), 'PipelinesList')
const ProcessDetails = lazy(() => import('@pages/Pipelines/ProcessDetails'), 'ProcessDetails')
const ProcessesList = lazy(() => import('@pages/Pipelines/ProcessesList'), 'ProcessesList')

const TeamDashboard = lazy(() => import('@msteams/pages/TeamDashboard'), 'TeamDashboard')
const Config = lazy(() => import('@msteams/pages/Config'), 'Config')
const NotSynchronized = lazy(() => import('@msteams/pages/NotSynchronized'), 'NotSynchronized')

const RFQsList = lazy(() => import('@src/pages/Accounting/RFQ/List'), 'RfqList')
const RFQDetails = lazy(() => import('@src/pages/Accounting/RFQ/Details'), 'RfqDetails')
const RFQProposalDetails = lazy(() => import('@src/pages/Accounting/RFQ/ProposalDetails'), 'RfqProposalDetails')

const Auth = lazy(() => import('@msteams/pages/Auth'), 'Auth')
const AuthLayout = lazy(() => import('@msteams/components/AuthLayout'), 'AuthLayout')

const Tasks = lazy(() => import('@pages/Tasks/List'), 'Tasks')

const NotFound = lazy(() => import('@pages/NotFound'), 'NotFound')
const Onboarding = lazy(() => import('@pages/Onboarding'), 'Onboarding')

const commonRoutes = [
  {
    path: routes.public.auth,
    element: <Auth />,
  },
  {
    path: routes.public.notFound,
    element: <NotFound />,
  },
]

export const personalRouter = createHashRouter([
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        element: <OnboardingLayout />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.tab,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.dashboard,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.approvalRequests,
                element: <ApprovalRequests />,
              },
              {
                path: routes.private.approvalRequestsById,
                element: <ApprovalRequestDetails />,
              },
              {
                path: routes.private.approvalRequestsByIdAction,
                element: <ApprovalRequestDetails />,
              },
              {
                path: routes.private.approvalRequest,
                element: <ApprovalRequestsForm />,
              },
              {
                path: routes.private.approvalRequestEditById,
                element: <ApprovalRequestsForm />,
              },
              {
                path: routes.private.notSynchronized,
                element: <NotSynchronized />,
              },
              {
                path: routes.private.tasks,
                element: <Tasks />,
              },
              {
                path: routes.private.taskBoards,
                element: <Tasks />,
              },

              {
                path: routes.private.purchaseOrders,
                element: <PurchaseOrdersList />,
              },
              {
                path: routes.private.purchaseOrdersById,
                element: <PurchaseOrderDetails />,
              },
              {
                path: routes.private.bills,
                element: <InvoicesList />,
              },
              {
                path: routes.private.billById,
                element: <InvoiceDetails />,
              },

              {
                path: routes.private.invoices,
                element: <InvoicesList />,
              },
              {
                path: routes.private.invoiceById,
                element: <InvoiceDetails />,
              },

              {
                path: routes.private.quotes,
                element: <QuotesList />,
              },

              {
                path: routes.private.quoteById,
                element: <QuoteDetails />,
              },

              {
                element: <ContactListLayout />,
                children: [
                  {
                    path: routes.private.contacts,
                    element: (
                      <ProtectedRoute adminOnly>
                        <VendorsList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.contactsList,
                    element: (
                      <ProtectedRoute adminOnly>
                        <VendorsList />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                element: <ContactLayout />,
                children: [
                  {
                    path: routes.private.contact,
                    element: (
                      <ProtectedRoute adminOnly>
                        <VendorForm />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.contactContracts,
                    element: (
                      <ProtectedRoute adminOnly>
                        <VendorContractsList />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: routes.private.contactContract,
                element: (
                  <ProtectedRoute adminOnly>
                    <ContractForm />
                  </ProtectedRoute>
                ),
              },
              {
                path: routes.private.contact,
                element: (
                  <ProtectedRoute adminOnly>
                    <ContractsList />
                  </ProtectedRoute>
                ),
              },

              { path: routes.private.processes, element: <ProcessesList /> },
              { path: routes.private.pipelineProcesses, element: <ProcessesList /> },
              { path: routes.private.pipelines, element: <PipelinesList /> },
              {
                path: routes.private.pipelineProcessesById,
                element: <ProcessDetails />,
              },
              {
                path: routes.private.pipelineProcesses,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.pipelineApprovalRequest,
                element: <ApprovalRequestsForm />,
              },
              {
                path: routes.private.pipelineById,
                element: (
                  <ProtectedRoute adminOnly>
                    <PipelineBuilder />
                  </ProtectedRoute>
                ),
              },
              {
                path: routes.private.rfqs,
                element: <RFQsList />,
              },
              {
                path: routes.private.rfqProposalsById,
                element: <RFQProposalDetails />,
              },
              {
                path: routes.private.rfqById,
                element: <RFQDetails />,
              },
            ],
          },
        ],
      },
      {
        path: routes.private.onboarding,
        element: <Onboarding />,
      },
    ],
  },

  ...commonRoutes,
])

export const teamRouter = createHashRouter([
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        element: <OnboardingLayout />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.tab,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.dashboard,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.approvalRequests,
                element: <TeamDashboard />,
              },
              {
                path: routes.private.notSynchronized,
                element: <NotSynchronized />,
              },
            ],
          },
        ],
      },
      {
        path: routes.private.config,
        element: <Config />,
      },
      {
        path: routes.private.onboarding,
        element: <Onboarding />,
      },
    ],
  },
  ...commonRoutes,
])
