export enum LinkGroupType {
  payable = 'payable',
  receivable = 'receivable',
}

export type LinkGroupData = {
  getOptions(isAdmin: boolean): Option<string>[]
  isActive(path: string): boolean
  type: LinkGroupType
}
